const defaultState = {
    isAuth: false
}
const SET_AUTH = 'SET_AUTH';
export const authReduser = (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {...state, isAuth: action.payload};
        default:
            return state;
    }
}
  