import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableItem from '../components/TableItem/TableItem'
import s from '../components/TableItem/style.module.css'
import Header from '../containers/Header/Header';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import {
  useQuery,
} from 'react-query'

const Table = () => {
  const [isSorted, setIsSorted] = useState(false);
  const [search, setSearch] = useState('');
  const leads = useSelector(state => state.leads.leads);
  const [defaultList, setDefaultList] = useState([...leads])
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState('');

  const URL = process.env.REACT_APP_SERVER_GET_LEAD_URL;

  const { data, isLoading, error } = useQuery(
    ['leads'], 
    async () => {
      const ls = localStorage.getItem('token');
      if (ls) {
        const response = await axios.get(`${URL}`, {
          headers: {
            Authorization: `Token ${ls}`,
          },
        });
        dispatch({ type: 'GET_LEADS', payload: response.data.data });
        setDefaultList(response.data.data);
        return response.data.data;
      } 
      else {
        dispatch({ type: 'SET_AUTH', payload: false });
        navigator('/auth');
      } 
    },
    {
      keepPreviousData: true,
    }
  );
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const filteredData = Array.isArray(data)
  ? data.filter(item =>
    item.phone_number
      .replace(/\D/g, '')
      .replace(/^(\+7)?(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$2$3$4$5')
      .toLowerCase()
      .includes(
        search
          .replace(/\D/g, '')
          .replace(/^(\+7)?(\d{3})(\d{3})(\d{2})(\d{2})$/, '$2$3$4$5')
          .toLowerCase()
      ) && (selectedStatus === '' || item.status === selectedStatus)
  )
  : [];

  /**
    * Сортирует массив defaultList по свойству created_at каждого объекта.
    * Если список уже отсортирован в порядке возрастания, он сортирует его в порядке убывания.
    * В противном случае, он сортирует его в порядке возрастания.
  */

  const handleSort = () => {
    isSorted ? 
      setDefaultList(defaultList.sort((a, b) => Date.parse(a.created_at) > Date.parse(b.created_at) ? 1 : -1)) :
      setDefaultList(defaultList.sort((a, b) => Date.parse(a.created_at) < Date.parse(b.created_at) ? 1 : -1));
    setIsSorted(!isSorted);
  }

  const handleSortStatus = (event) => {
    setSelectedStatus(event.target.value);
  };
  

  return (
    <>
      <Header search={search} setSearch={setSearch}/>
         <div className={s.table_container}>
          <table className={s.table}>
            <thead>
              <tr>
                <td className={s.table_header} width={'3%'}>ID</td>
                <td className={s.table_header}>Имя</td>
                <td className={s.table_header}>Фамилия</td>
                <td className={s.table_header}>Отчество</td>
                <td className={s.table_header}>E-mail</td>
                <td className={s.table_header}>Номер телефона</td>
                <td className={s.table_header}>Регион</td>
                <td className={s.table_header}>
                  <select className={s.select} defaultValue={''} onChange={e => handleSortStatus(e)}>
                    <option value={''}>Статус</option>
                    <option value={'waiting'}>waiting</option>
                    <option value={'in_progress'}>in_progress</option>
                    <option value={'canceled'}>canceled</option>
                    <option value={'completed'}>completed</option>
                  </select>
                </td>
                <td className={s.table_header}>
                  <button onClick={handleSort}>Дата создания <span>{isSorted ? '↑' : '↓'}</span></button>
                </td>
                <td className={s.table_header} width={'5%'}>Lead ID</td>
              </tr>
            </thead>
            <tbody>
              {
                filteredData.length ? 
                isLoading ? 
                  <tr><td height={'100px'} width={'100%'} colSpan={10}><div className='loader'></div></td></tr> :
                  filteredData.map((lead, index) => (
                    <TableItem key={index} {...lead}/>
                  ))
                : 
                <><tr><td colSpan={10}>Записей не найдено</td></tr></>
              }
            </tbody>
          </table>
        </div>
    </>
  )
}

export default Table