import React from 'react'
import Auth from '../containers/Auth/Auth'

const Authorization = () => {

  return (
    <>
        <Auth />
    </>
  )
}

export default Authorization