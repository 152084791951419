const defaultState = {
    leads: []
}
const GET_LEADS = 'GET_LEADS';
export const leadReduser = (state = defaultState, action) => {
    switch (action.type) {
        case GET_LEADS:
            return {...state, leads: [...action.payload]}
        default:
            return state;
    }
}
  