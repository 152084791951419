import './App.css';
import { Routes, Route } from 'react-router-dom';
import Authorization from './pages/Authorization';
import Table from './pages/Table';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Table />} />
        <Route path="/auth" element={<Authorization />} />
      </Routes>
    </div>
  );
}

export default App;