import React, { useEffect, useState } from 'react'
import s from './style.module.css'
import * as XLSX from 'xlsx'
import { utils } from 'xlsx';
import { useSelector } from 'react-redux';

const Header = ({search, setSearch}) => {
    // const leads = useSelector(state => state.leads.leads)
    const leads = useSelector(state => {
        return state.leads.leads;
    });

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const pattern = /\D/g;

    // phone field 
    const handleChangePhone = (e) => {
        let inputNumbersValue = onPhoneInput(e)

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
            let formattedInputValue = firstSymbols + ' '
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
            setSearch(formattedInputValue)
        }

        function onPhoneInput (e) {
            return e.target.value.replace(pattern, '')
        }
    }

    // date unload fields 
    const handleUnload = (e) => {
        e.preventDefault()

        const filtredLeads = leads.filter(lead => {
            const leadDate = new Date(lead.created_at);
            const fromDate = new Date(Date.parse(from));
            const toDate = new Date(Date.parse(to));

            return leadDate >= fromDate && leadDate <= toDate;
        });

        const headers = ['Имя', 'Фамилия', 'Отчество', 'Емейл', 'Номер', 'Регион', 'Lead ID', 'Создан', 'ID', 'Статус'];

        const data = [headers, ...filtredLeads.map(lead => Object.values(lead))];

        const worksheet = utils.aoa_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `Выгрузка Лидов с ${from} по ${to}.xlsx`;
        link.click();

        URL.revokeObjectURL(url);
    }
  return (
    <header className={s.header}>
        <div className={s.header_container}>
            <div className={s.header_field}>
                <label htmlFor="search">Поиск</label>
                <input type="tel" name="search" id='search' className={s.search} placeholder='+7 ...' onChange={e => handleChangePhone(e)} value={search} />
            </div>
        </div>
        
        <div className={s.header_container}>
            <div className={s.header_field}>
                <label htmlFor="from">От</label>
                <input type="date" name="from" id='from' className={s.search} placeholder='' onChange={e => setFrom(e.target.value)}/>
            </div>
            <div className={s.header_field}>
                <label htmlFor="to">До</label>
                <input type="date" name="to" id='to' className={s.search} placeholder='' onChange={e => setTo(e.target.value)} />
            </div>
            <div className={s.header_unload}>
                <button disabled={!to || !from} onClick={(e) => handleUnload(e)}>Выгрузить</button>
            </div>
        </div>
    </header>  
  )
}

export default Header