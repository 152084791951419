import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import s from './style.module.css'
import { useDispatch } from 'react-redux'

const Auth = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [authError, setAuthError] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const AUTH_URL = process.env.REACT_APP_SERVER_SET_AUTH;

    // Authorization
    const autenitificate = async (username, password) => {
        setIsLoading(true)
        try {
          const res = await axios.post(AUTH_URL, {
            username,
            password
          })
          localStorage.setItem('token', res.data.auth_token)
          dispatch({type: 'SET_AUTH', payload: true})
        } catch (error) {
          console.warn(`Ошибка авторизации:  ${error}`);
          setAuthError(true)
          setIsLoading(false)
        }
    }
    // Обрабатывает отправку формы для аутентификации пользователя.
    const handleSubmit = async (e) => {
        e.preventDefault()

        const login = e.target[0].value
        const password = e.target[1].value
        try {
            await autenitificate(login, password)
            if (localStorage.getItem('token')){
                navigate('/');
            }
        } catch (error) {
            console.warn(`Ошибка авторизации:  ${error}`)
        }
    }

    // Validation Form
    const handleLogin = (e) => {
        setLogin(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    // Error
    const handleBlur = () => {
        if (login === '' || password === '') {
            setError(true)
        } 
        else {
            setError(false)
        }
    }

  return (
    <div className={s.auth}>
        <div className={s.auth_container}>
            <div className={s.auth_fields}>
                <div className={s.auth_title}>
                    <h1>Войдите<p>в систему...</p></h1>
                </div>
                <form type="submit" onSubmit={(e) => handleSubmit(e)}>
                    <div className={s.auth_field}>
                        <label htmlFor="login">Логин</label>
                        <input type="text" name="login" id="login" 
                        onChange={(e) => {
                            handleLogin(e)
                            if (password.length > 0) {
                                setError(false)
                            }
                        }} 
                        value={login} onBlur={handleBlur}/>
                    </div>
                    <div className={s.auth_field}>
                        <label htmlFor="password">Пароль</label>
                        <input type="password" name="password" id="password" 
                        onChange={(e) => {
                            handlePassword(e)
                            if (login.length > 0) {
                                setError(false)
                            }
                        }} 
                        value={password} onBlur={handleBlur}/>
                    </div>
                    {error && <span className={s.auth_field_error}>Все поля должны быть заполнены</span>}
                    {authError && <span className={s.auth_field_error}>Неверный логин или пароль</span>}
                    <button type="submit" disabled={(error) ? true : false} className={error ? s.err_button : s.auth_button}>Войти</button>
                </form>
            </div>
            <div className={s.auth_picture}>
                <div className={s.auth_gif}>
                    <img src={require('../../assets/gmomik.gif')} alt="auth gif"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Auth