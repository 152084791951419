import React from 'react'

const TableItem = ({...leads}) => {
  return (
    <> 
      <tr>
        <td >{leads.id}</td>  
        <td title={leads.name}>{leads.name}</td>  
        <td title={leads.surname}>{leads.second_name}</td>  
        <td title={leads.second_name}>{leads.surname}</td>  
        <td title={leads.email}>{leads.email}</td>  
        <td title={leads.phone}>{leads.phone_number}</td>  
        <td title={leads.region}>{leads.geo}</td>  
        <td style={
          leads.status === 'completed' ? {color: '#3ccf3c', fontWeight: 'bold'} : 
          leads.status === 'canceled' ? {color: '#fa3c32', fontWeight: 'bold'} : 
          leads.status === 'in_progress' ? {color: '#deb74b'} : {}}>{leads.status}</td>  
        <td title={leads.created_at}>{leads.created_at}</td>  
        <td >{leads.lead_id}</td>  
      </tr> 
    </>
  )
}

export default TableItem